





































































































import { Vue, Component, Provide } from 'vue-property-decorator';
import Nav from '@/components/header.vue';
import serve from '@/serve/mine';
import area from '@/utils/area';
import { Toast } from 'vant';
@Component({
  components: {
    Nav,
  },
})
export default class Account extends Vue {
  userInfo = {};
  areaData = [];
  headimg = '';
  fileList = [];
  @Provide() username = '';
  @Provide() phone = '';
  @Provide() QQ = '471464602';
  @Provide() email = '2819152915@QQ.com';
  @Provide() show = false;
  @Provide() areaList = area; // 省市区数据
  @Provide() address = '';
  @Provide() option = {
    img: 'https://img.yzcdn.cn/vant/cat.jpeg',
    outputSize: 1, //剪切后的图片质量（0.1-1）
    full: false, //输出原图比例截图 props名full
    outputType: 'png',
    canMove: true,
    original: false,
    canMoveBox: false,
    autoCrop: true,
    fixedBox: true,
  };
  created() {
    this.getUserInfo();
  }
  async getChina() {
    const res = await serve.getChina();
    this.areaData = res.data.data;
  }
  private goTo(val: string): void {
    this.$router.push(val);
  }
  async afterRead(file: File) {
    // const  self = this
    const fd = new FormData();
    fd.append('file', file['file']);
    const res = await serve.upload(fd);
    if (res.data.code === 0) {
      this.headimg = res.data.data[0];
      serve.editUserInfo({ avatar: res.data.data[0] }).then((Response) => {
        if (Response.data.code === 0) {
          Toast.success('修改成功');
          this.getUserInfo();
        } else {
          Toast.fail(Response.data.message);
        }
      });
    }
  }
  delImg(): void {
    this.headimg = '';
  }
  onOversize(): void {
    Toast.fail('文件大小不能超过2MB');
  }
  goBack(): void{
    this.$router.push('/poket')
  }
  async submit() {
    const data = {
      email: this.userInfo['email'],
      qq: this.userInfo['qq'],
    };
    const res = await serve.editUserInfo(data);
    if (res.data.code === 0) {
      Toast.success('提交成功');
      this.getUserInfo();
    } else {
      Toast.fail(res.data.message);
    }
  }
  async getUserInfo() {
    this.fileList = [];
    const res = await serve.getUserInfo();
    if (res.data.code === 0) {
      this.userInfo = res.data.data;
      this.phone = res.data.data.phone;
      this.fileList.push({
        url: res.data.data.avatar,
      });
    }
  }
  async define(val: object) {
    this.userInfo['city'] = val[0].name + val[1].name + val[2].name;
    const areaId: any = this.areaData.map((item) => {
      if (item.name === val[2].name) {
        return item.id;
      }
    });
    const res = await serve.editArea(areaId);
    if (res.data.code === 0) {
      Toast.success('申请提交成功，正在审核...');
    } else {
      Toast.fail(res.data.message);
    }
    this.show = false;
  }
}
